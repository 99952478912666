import React, { useState ,useContext} from "react";
import AuthContext from "../../store/auth-context";

const MentorMenties = ({courseId}) => {
    const authCtx = useContext(AuthContext);
  const [emails, setEmails] = useState([
    { email: "", status: "" }, // İlk e-posta
    { email: "", status: "" }, // İkinci e-posta
    { email: "", status: "" }, // Üçüncü e-posta
    { email: "", status: "" }, // Dördüncü e-posta
    { email: "", status: "" }, // Beşinci e-posta
  ]);

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index].email = value;
    setEmails(updatedEmails);
  };

  const handleEmailSubmit = async (index) => {
    const updatedEmails = [...emails];
    updatedEmails[index].status = "Bekleniyor...";
    setEmails(updatedEmails);

    try {
    //   const response = await fetch("/api/register-email", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ email: emails[index].email }),
    //   });

    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/userCourseMenties`,
        {
            method: "POST",
            headers: {
                Authorization: `${authCtx.token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: emails[index].email, courseId:courseId }),
        }
    );
    const data = await response.json();
    console.log("chunkItems");
    console.log(data);

      if (data.state=="Bekleniyor") {
        updatedEmails[index].status = "Kayıt oldu";
      } else {
        updatedEmails[index].status = "Hata oluştu";
      }
    } catch (error) {
      updatedEmails[index].status = "Hata oluştu";
    }

    setEmails(updatedEmails);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold text-gray-700 text-center mb-6">
          E-posta Kayıt Formu
        </h2>
        <div className="space-y-4">
          {emails.map((item, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                type="email"
                placeholder={`E-posta ${index + 1}`}
                value={item.email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                className="flex-grow px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              <button
                onClick={() => handleEmailSubmit(index)}
                className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                disabled={item.status === "Kayıt oldu"}
              >
                Gönder
              </button>
              <span
                className={`text-sm font-medium ${
                  item.status === "Kayıt oldu"
                    ? "text-green-600"
                    : item.status.includes("Hata")
                    ? "text-red-600"
                    : "text-gray-600"
                }`}
              >
                {item.status}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default MentorMenties;

