import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  user: {},
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  
  
  // const navigate = useNavigate();

  // const checkToken = () => {
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     navigate('/login'); // Giriş ekranına yönlendir
  //   }
  //   // Token'in süresini kontrol et
  //   const tokenExpiry = localStorage.getItem('tokenExpiry');
  //   if (tokenExpiry && new Date().getTime() > tokenExpiry) {
  //     localStorage.removeItem('token');
  //     navigate('/login');
  //   }
  // };

  // useEffect(() => {
  //   checkToken();
  // }, []);

  const initialToken = localStorage.getItem("token");
  let initialUser;



  try {
    initialUser = initialToken ? jwtDecode(initialToken) : {};
  } catch (error) {
    console.error("Error decoding initial token:", error);
    initialUser = {};
  }

  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);

  const userIsLoggedIn = !!token;

  const logoutHandler = () => {
    setToken(null);
    setUser({});
    localStorage.removeItem("token");
  };

  const loginHandler = (token) => {
    setToken(token);
    try {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
    } catch (error) {
      console.error("Error decoding login token:", error);
      setUser({});
    }
    localStorage.setItem("token", token);
  };

  // Örneğin: Token süresini kontrol etmek ve gerekirse logout işlemi yapmak
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          // Token süresi geçmişse logout işlemi yapabilirsiniz.
          logoutHandler();
        }
      } catch (error) {
        console.error("Error decoding token during expiration check:", error);
      }
    }
  }, [token]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    user: user,
    login: loginHandler,
    logout: logoutHandler,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
