import React, { useState, useRef, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";
import { Link, useNavigate, NavLink } from "react-router-dom";

const Payment = () => {
	const authCtx = useContext(AuthContext);
	const [userBaskets, setUserBaskets] = useState([]);
	const { data, error, loading, sendRequest } = useHttp();
	const navigate = useNavigate();

	useEffect(() => {
		getUserBasket();
	}, []);

	const getUserBasket = async () => {
		// const data2 = { paid:true };
		if (authCtx.token !== null) {
			sendRequest("/userCoursePayments");
		}
	};

	useEffect(() => {
		if (data != null && userBaskets.length == 0) {
			setUserBaskets(data.userCoursePayments);
			console.log(data);
			console.log("girdi DATA1");
		}
	}, [data]);

	const handlePayment = async (uniqId) => {
		const dataPayment = { courseUniqId: uniqId };
		console.log("pay");
		console.log(dataPayment);
		await sendRequest(`/userCoursePayments/${uniqId}`, "PUT");
		navigate("/profile");
	};

	const BasketItem = ({ item, onNavigate, onPayment }) => {
		return (
			<div className="flex flex-col sm:flex-row justify-between items-center p-4 border border-gray-300 rounded-lg shadow-md bg-white hover:bg-gray-100 transition-all">
				<div className="flex-1 text-lg sm:text-xl font-semibold text-center sm:text-left">
					{item.basketCourse.name}
				</div>
				<div className="flex-1 text-lg sm:text-xl font-semibold text-center sm:text-left">
					{item.basketCourse.price} TL
				</div>
				<div className="flex-1 text-sm sm:text-base text-gray-500 text-center sm:text-left">
					{item.basketCourse.createdAt && new Date(item.basketCourse.createdAt).toLocaleDateString()}
				</div>
				<div className="flex flex-col sm:flex-row items-center gap-3">
					{item.paid ? (
						<>
							<span className="text-green-600 font-medium">Ödendi</span>
							<button
								className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all"
								onClick={onNavigate}
							>
								Kursa Git
							</button>
						</>
					) : (
						<button
							className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-all"
							onClick={() => onPayment(item.basketCourse.uniqId)}
						>
							Öde
						</button>
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="max-w-4xl mx-auto py-8 px-4">
				<h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">
					Sepet
				</h1>
				{userBaskets.length === 0 ? (
					<div className="text-center text-gray-500 text-lg">
						Sepetiniz boş. Eklemek için kurslara göz atın!
					</div>
				) : (
					<div className="flex flex-col gap-4">
						{userBaskets.map((item) => (
							<BasketItem
								key={item.basketCourse.uniqId}
								item={item}
								onNavigate={() => navigate("/profile")}
								onPayment={handlePayment}
							/>
						))}
					</div>
				)}
			</div>
			{/* <div>
			<div className='flex flex-col items-center gap-3 px-2 sm:px-0'>
				<div className='text-xl sm:text-2xl'>Sepet</div>
				{userBaskets.length != 0 &&
					userBaskets.map((item) => (
						<div className='flex flex-col w-full border border-red-400'>
							<div className='flex flex-col sm:flex-row justify-around items-center py-2'>
								<div className='flex flex-col justify-center text-lg sm:text-2xl font-semibold my-1'>
									{item.basketCourse.name}
								</div>
								<div className='flex flex-col justify-center text-lg sm:text-2xl font-semibold my-1'>
									{item.basketCourse.price} TL
								</div>
								<div className='flex flex-col justify-center text-lg sm:text-2xl font-semibold my-1'>
									{item.basketCourse.createdAt}
								</div>
								<div className='flex flex-col justify-center items-center'>
									{item.paid == true ? (
										<div className='flex flex-row items-center gap-3'>
											<div className='button-warn text-sm sm:text-base'>Ödendi</div>
											<div
												className='bg-blue-400 p-2 sm:p-3 rounded-md m-2 cursor-pointer text-sm sm:text-base'
												onClick={() => {
													navigate("/profile");
												}}
											>
												Kursa Git
											</div>
										</div>
									) : (
										<div
											className='button-green text-sm sm:text-base'
											onClick={() => handlePayment(item.basketCourse.uniqId)}
										>
											Öde
										</div>
									)}
								</div>
							</div>
						</div>
					))}
			</div>
		</div> */}
		</>
	);
};

export default Payment;
