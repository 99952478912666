import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { useHttp } from "../hooks/useHttp";
import {
	BuildingLibraryIcon,
	UserIcon,
	ShoppingBagIcon,
	BookOpenIcon,
	MagnifyingGlassCircleIcon,
	Bars3Icon,
	BugAntIcon,
	PresentationChartBarIcon,
	MapPinIcon,
} from "@heroicons/react/24/solid";
// import logo from "../../public/logo.png"

const Layout2 = () => {
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);
	const [categories, setCategories] = useState([]);
	const [searchKey, setSearchKey] = useState("");
	const [userRoles, setUserRoles] = useState([]);

	const { data, error, loading, sendRequest } = useHttp();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	useEffect(() => {
		if (authCtx.user) {
			if (authCtx.user.roles) {
				console.log(`userRole`);
				console.log(userRoles);
				setUserRoles(authCtx.user.roles);
			} else {
				setUserRoles([]);
			}
		}
	}, [sendRequest, authCtx]);

	const logoutHandler = () => {
		authCtx.logout();
		setUserRoles([]);
		navigate("/");
	};
	const handleRoute = () => {
		if (searchKey == "") {
			navigate("/courses");
		} else {
			navigate(`/courses/${searchKey}/search`);
		}
		setSearchKey(null);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleRoute();
		}
	};
	const searchKeyHandler = (event) => {
		setSearchKey(event.target.value);
	};

	return (
		<>
			<div>
				<nav className="flex flex-col bg-red-500 text-white font-bold h-30">
					<div className="flex justify-between w-full my-2">
						<div className="flex justify-between">
							<div className="flex flex-col justify-center ml-4 ">
								<NavLink to="/" className=" h-10 w-10 md:h-24 md:w-24">
									<div>
										<img
											src="/logo.png"
											className=" bg-red-500 border border-white rounded-xl"
										/>
									</div>
								</NavLink>
							</div>
							<div className="hidden lg:flex flex-col justify-center ml-10 ">
								<span>Yazılım Mühendisi Cem GÖKÇE</span>
								<span>tarafından yapılmıştır.</span>
							</div>
						</div>
						{/* arama yeri kaldırıldı */}
						{/* <div className="hiddem md:flex md:flex-col">
							<div className="hidden sm:flex flex-row h-10 m-3 text-black">
								<div className=" flex flex-col justify-center">
									<input
										type="text"
										placeholder="search"
										onChange={searchKeyHandler}
										onKeyUp={handleKeyPress}
										className="rounded-lg h-10 xl:w-[500px] lg:w-[300px] md:w-[200px] sm:w-[100px]  m-3 ring-0 ring-white p-2"
									/>
								</div>
								<div className="flex flex-col justify-center">
									<span
										onClick={() => {
											handleRoute();
										}}
									>
										<MagnifyingGlassCircleIcon className="text-white h-8 w-8" />
									</span>
								</div>
							</div>
						</div> */}

						<div className="flex flex-row md:flex-col justify-center">
							<div className="flex">
								<div className="flex flex-col justify-center p-1 m-1">
									<NavLink to="/">
										<span className="flex justify-center">
											<BuildingLibraryIcon className="text-white h-6 w-6" />
										</span>
										<span className="hidden md:flex">Anasayfa</span>
									</NavLink>
								</div>
								{/* {authCtx.token != null && (
                                    <div className='flex flex-col justify-center p-1 m-1'>
                                        <NavLink to='/eks'>
                                            <BugAntIcon className='text-white h-6 w-6' />
                                            <span className="hidden md:flex">Katkı Yap</span>
                                        </NavLink>
                                    </div>
                                )} */}
								{authCtx.token != null && (
									<div className="flex flex-col justify-center p-1 m-1">
										<NavLink to="/profile">
											<UserIcon className="text-white h-6 w-6" />
											<span className="hidden md:flex">Profil</span>
										</NavLink>
									</div>
								)}
								{authCtx.token != null && (
									<div className="flex flex-col justify-center p-1 m-1">
										<NavLink to="/payment">
											<ShoppingBagIcon className="text-white h-6 w-6" />
											<span className="hidden md:flex">Sepet</span>
										</NavLink>
									</div>
								)}

								<div className="flex flex-col justify-center p-1 m-1">
									<NavLink to="/contact">
										<MapPinIcon className="text-white h-6 w-6" />
										<span className="hidden md:flex">İletişim</span>
									</NavLink>
								</div>

								{!authCtx.isLoggedIn ? (
									<div className="flex flex-col justify-center ">
										<Link to="/auth">
											<button className="bg-green-500 p-3 rounded-full">
												Giriş Yap
											</button>
										</Link>
									</div>
								) : (
									<div className="flex flex-col justify-center">
										<button
											onClick={logoutHandler}
											className="bg-green-500 p-3 m-1 rounded-md md:ml-4"
										>
											Çıkış
										</button>
									</div>
								)}
							</div>
							<div className="flex">
								<div className="flex flex-col justify-center">
									{(userRoles.includes("Teacher") ||
										userRoles.includes("Admin")) && (
										<div className="md:border md:border-white md:p-1 rounded-lg m-2">
											<NavLink to="/teacherCourses">
												<BookOpenIcon className="text-white h-6 w-6" />
												<span className="hidden md:flex">Teacher</span>
											</NavLink>
										</div>
									)}
								</div>
								<div className="flex flex-col justify-center">
									{userRoles.includes("Admin") && (
										<div className="md:border md:border-white md:p-1 rounded-lg m-2">
											<NavLink to="/admin">
												<PresentationChartBarIcon className="text-white h-6 w-6" />
												<span className="hidden md:flex">Admin</span>
											</NavLink>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Layout2;
