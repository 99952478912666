import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

const CourseAdd = ({ setState, course, setCourse }) => {
	const [categoryId, setCategoryId] = useState(null);
	const [courseName, setCourseName] = useState(null);
	const [courseDesc, setCourseDesc] = useState(null);
	const [categories, setCategories] = useState(null);
	const [goal, setGoal] = useState("");
	const [savedGoals, setSavedGoals] = useState([]);
	const [plusGoal, setPlusGoal] = useState(1);

	const [forWho, setForWho] = useState("");
	const [savedForWho, setSavedForWho] = useState([]);
	const [plusForWho, setPlusForWho] = useState(1);

	const [courseTotalCompleteTimeType, setCourseTotalCompleteTimeType] = useState(null);
	const [courseTotalCompleteTimeNumber, setCourseTotalCompleteTimeNumber] = useState(null);

	const [chunkTimeType, setChunkTimeType] = useState(null);
	const [chunkTimeNumber, setChunkTimeNumber] = useState(null);
	const [courseTimeType, setCourseTimeType] = useState(null);
	const [courseTimeNumber, setCourseTimeNumber] = useState(null);

	const [courseActive, setCourseActive] = useState(false);
	const [mentorCourse, setMentorCourse] = useState(false);

	const authCtx = useContext(AuthContext);
	//goal
	const addGoal = () => {
		if (goal.trim() !== "") {
			const newGoal = {
				order: savedGoals.length + plusGoal,
				goal: goal,
			};

			setSavedGoals([...savedGoals, newGoal]);

			// Clear fields
			setGoal("");
		} else {
			alert(`lutfen bos alanlari doldurunuz`);
		}
	};

	const handleGoalRemove = (ord) => {
		console.log(`remove calisti`);
		const removeWord = savedGoals.filter((item) => item.order !== ord);
		if (removeWord.length == 0) {
			setPlusGoal(1);
		} else {
			setPlusGoal((prev) => prev + 1);
		}

		setSavedGoals(removeWord);
	};

	//for who
	const addForWho = () => {
		if (forWho.trim() !== "") {
			const newForWho = {
				order: savedForWho.length + plusForWho,
				forWho: forWho,
			};

			setSavedForWho([...savedForWho, newForWho]);

			// Clear fields
			setForWho("");
		} else {
			alert(`lutfen bos alanlari doldurunuz`);
		}
	};

	const handleForWhoRemove = (ord) => {
		console.log(`remove calisti`);
		const removeForWho = savedForWho.filter((item) => item.order !== ord);
		if (removeForWho.length == 0) {
			setPlusForWho(1);
		} else {
			setPlusForWho((prev) => prev + 1);
		}

		setSavedForWho(removeForWho);
	};

	const handleCategoryId = (e) => {
		console.log(e.target.value);
		setCategoryId(e.target.value);
	};

	const handleCourseName = (e) => {
		console.log(e.target.value);
		setCourseName(e.target.value);
	};

	const handleCourseDesc = (e) => {
		console.log(e.target.value);
		setCourseDesc(e.target.value);
	};

	const handleCourseTotalTimeType = (e) => {
		console.log(e.target.value);
		setCourseTotalCompleteTimeType(e.target.value);
	};
	const handleCourseTotalTimeNumber = (e) => {
		console.log(e.target.value);
		setCourseTotalCompleteTimeNumber(e.target.value);
	};

	const handleChunkTimeType = (e) => {
		console.log(e.target.value);
		setChunkTimeType(e.target.value);
	};

	const handleChunkTimeNumber = (e) => {
		console.log(e.target.value);
		setChunkTimeNumber(e.target.value);
	};

	const handleCourseTimeType = (e) => {
		console.log(e.target.value);
		setCourseTimeType(e.target.value);
	};

	const handleCourseTimeNumber = (e) => {
		console.log(e.target.value);
		setCourseTimeNumber(e.target.value);
	};

	const toggleCheckbox = () => {
		setCourseActive(!courseActive);
	};

	const handleSubmitCourseAdd = () => {
		const data = {
			categoryId: categoryId,
			goals: savedGoals.length !== 0 ? JSON.stringify(savedGoals) : null,
			forWho: savedForWho.length !== 0 ? JSON.stringify(savedForWho) : null,
			name: courseName,
			description: courseDesc,
			totalCompleteTimeType: courseTotalCompleteTimeType,
			totalCompleteTimeNumber: courseTotalCompleteTimeNumber,
			chunkTimeType: chunkTimeType,
			chunkTimeNumber: chunkTimeNumber,
			courseTimeType: courseTimeType,
			courseTimeNumber: courseTimeNumber,
			mentorCourse:mentorCourse,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/courses`, {
			method: "POST",
			mode: "cors",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setState(false);
				console.log(`setsette false`);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};
	const handleSubmitCourseUpdate = () => {
		const data = {
			categoryId: course.categoryId,
			goals: savedGoals.length !== 0 ? JSON.stringify(savedGoals) : null,
			forWho: savedForWho.length !== 0 ? JSON.stringify(savedForWho) : null,
			name: courseName,
			description: courseDesc,
			totalCompleteTimeType: courseTotalCompleteTimeType,
			totalCompleteTimeNumber: courseTotalCompleteTimeNumber,
			chunkTimeType: chunkTimeType,
			chunkTimeNumber: chunkTimeNumber,
			courseTimeType: courseTimeType,
			courseTimeNumber: courseTimeNumber,
			mentorCourse:mentorCourse,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/courses/${course.id}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setState(false);
				// console.log("data");
				// console.log(data);
				// setCourse(data);
				console.log(`setsette false`);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	const getCategories = async () => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/categories/`);
		const data = await response.json();
		setCategories(data);
		console.log(data);
		// setIsLoading(false);
	};

	useEffect(() => {
		getCategories();
	}, []);

	useEffect(() => {
		if (course != null) {
			console.log("course2");
			console.log(course);
			setCourseName(course.name);
			setCourseDesc(course.description);
			setCategoryId(course.categoryId);
			if (course.goal !== null) {
				setSavedGoals(JSON.parse(course.goals));
			}
			if (course.forWho !== null) {
				setSavedForWho(JSON.parse(course.forWho));
			}
			//setSavedForWho(JSON.parse(course.forWho));

			setCourseTotalCompleteTimeType(course.totalCompleteTimeType);
			setCourseTotalCompleteTimeNumber(course.totalCompleteTimeNumber);

			setChunkTimeType(course.chunkTimeType);
			setChunkTimeNumber(course.chunkTimeNumber);
			setCourseTimeType(course.courseTimeType);
			setCourseTimeNumber(course.courseTimeNumber);
			setMentorCourse(course.mentorCourse)
			console.log("course.mentorCourse")
			console.log(course.mentorCourse)
		}
	}, [course]);

	return (
		<>
			<div className='bg-red-300 p-2 md:p-4 w-full'>
				<div className='flex flex-col md:flex-row justify-center gap-4'>
					<div className='bg-red-300 p-4'>
						<h2 className='text-xl md:text-2xl font-semibold text-center'>Kurs Başlangıç Kaydı</h2>

						<div className='flex flex-col gap-2'>
							<div className='flex flex-row justify-between'>
								<label className='w-32'>Kategori:</label>
								<select name='cars' value={categoryId} id='cars' className='p-2 flex-grow' onChange={handleCategoryId}>
									<option value='yok'>Bir kategori seçiniz</option>
									{categories &&
										categories.map((item) => (
											<option key={item.id} value={item.id}>
												{item.name}
											</option>
										))}
								</select>
							</div>

							<div className='flex flex-row justify-between'>
								<label className='w-32'>Kurs Adı:</label>
								<input
									type='text'
									placeholder='Please type course name...'
									value={courseName}
									onChange={handleCourseName}
									className='p-2 flex-grow'
								/>
							</div>

							<div className='flex flex-row justify-between'>
								<label className='w-32'>Kurs Açıklaması:</label>
								<textarea
									placeholder='Please type course description...'
									onChange={handleCourseDesc}
									value={courseDesc}
									rows={5}
									className='p-2 flex-grow'
								/>
							</div>
							<div className='flex flex-row justify-start'>
								<label className='w-32'>Mentor kursu</label>
								<input type="checkbox" onClick={()=> {setMentorCourse(!mentorCourse)}} checked={mentorCourse}/>
							</div>
							
						</div>
					</div>

					<div className='bg-red-300 p-4'>
						<div className='flex flex-col justify-center gap-4 bg-red-300 p-4 mt-10'>
							<div className='flex flex-row gap-1 '>
								<div className='flex flex-col justify-center w-32'>Kurs toplam zaman: </div>
								<input
									type='number'
									value={courseTotalCompleteTimeNumber}
									onChange={handleCourseTotalTimeNumber}
									className='p-2 w-20'
								/>
								<select className='p-1' value={courseTotalCompleteTimeType} onChange={handleCourseTotalTimeType}>
									<option value='yok'>Zaman Secin</option>
									<option value='gunde'>Gunde</option>
									<option value='haftada'>Haftada</option>
									<option value='ayda'>Ayda</option>
								</select>
								<div className='flex flex-col justify-center'>tamalanacak.</div>
							</div>
							<div className='flex flex-row gap-4'>
								<div className='flex flex-col justify-center w-32'>Çalışma düzeni: </div>
								<div className='flex flex-col justify-center'>Her</div>
								<select className='p-2' value={courseTimeType} onChange={handleCourseTimeType}>
									<option value='yok'>Zaman Secin</option>
									<option value='gunde'>Gunde</option>
									<option value='haftada'>Haftada</option>
									<option value='ayda'>Ayda</option>
								</select>
								<input type='number' value={courseTimeNumber} onChange={handleCourseTimeNumber} className='p-2 w-20' />
								<div className='flex flex-col justify-center'>defa calisacak.</div>
							</div>
							<div className='flex flex-row gap-4 '>
								<div className='flex flex-col justify-center w-32'>Chunk time: </div>
								<div className='flex flex-col justify-center'>Bir kerede </div>
								<input type='number' value={chunkTimeNumber} onChange={handleChunkTimeNumber} className='p-2 w-20' />
								<select className='p-2' value={chunkTimeType} onChange={handleChunkTimeType}>
									<option value='yok'>Zaman Secin</option>
									<option value='Saat'>Saat</option>
									<option value='Dakika'>Dakika</option>
									{/* <option value='Gun'>Gun</option>
								<option value='Hafta'>Hafta</option>
								<option value='Ay'>Ay</option> */}
								</select>
								<div className='flex flex-col justify-center'>calisacak.</div>
							</div>
							<div className='border border-blue-300 m-2 p-2'>
								<div className='font-bold p-2'>Aciklama</div>
								{courseTimeNumber && courseTimeType && (
									<div>
										{" "}
										(Course) calisma şekli:{" "}
										<span className='font-bold'>
											{" "}
											Her {courseTimeType} {courseTimeNumber} defa çalışılması gerekiyor.
										</span>
									</div>
								)}
								{chunkTimeNumber && chunkTimeType && (
									<div>
										{" "}
										Bir defada:{" "}
										<span className='font-bold'>
											{" "}
											{chunkTimeNumber} {chunkTimeType} çalışılıcak.
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className='flex flex-col md:flex-row justify-between gap-4 p-4'>
					<div className='bg-blue-300 p-4 w-full md:w-1/2'>
						<div className='flex flex-row gap-2'>
							<div className='container p-4 border border-red-200 m-7 w-96'>
								<h2 className='text-2xl font-bold mb-4'>Kurs amaçları : </h2>

								<div className='mb-4'>
									<label className='block text-sm font-medium text-gray-700'>Amac:</label>
									<input
										type='text'
										className='mt-1 p-2 border border-gray-300 rounded-md w-full'
										value={goal}
										onChange={(e) => setGoal(e.target.value)}
									/>
								</div>

								<button className='bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600' onClick={addGoal}>
									Ekle
								</button>
							</div>
							<div className=' p-2 m-2'>
								<ul className='mt-4 mb-4'>
									{savedGoals.map((goal, index) => (
										<li
											key={index}
											className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between'
										>
											<span>
												<strong>Sıra: </strong> {goal.order},<strong>Amaç: </strong> {goal.goal}
											</span>
											<span
												className='w-auto p-2 bg-red-200 flex flex-col justify-center text-center cursor-pointer'
												onClick={() => handleGoalRemove(goal.order)}
											>
												{" "}
												Çıkar{" "}
											</span>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
					<div className='bg-blue-300 p-4 w-full md:w-1/2'>
						{" "}
						<div className='flex flex-row gap-2'>
							<div className='container p-4 border border-red-200 m-7 w-96'>
								<h2 className='text-2xl font-bold mb-4'>Kursa kimler katılabilir : </h2>

								<div className='mb-4'>
									<label className='block text-sm font-medium text-gray-700'>Kriter:</label>
									<input
										type='text'
										className='mt-1 p-2 border border-gray-300 rounded-md w-full'
										value={forWho}
										onChange={(e) => setForWho(e.target.value)}
									/>
								</div>

								<button className='bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600' onClick={addForWho}>
									Ekle
								</button>
							</div>
							<div className=' p-2 m-2'>
								<ul className='mt-4 mb-4'>
									{savedForWho.map((item, index) => (
										<li
											key={index}
											className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between'
										>
											<span>
												<strong>sira:</strong> {item.order},<strong>Kriter:</strong> {item.forWho}
											</span>
											<span
												className='w-auto p-2 bg-red-200 flex flex-col justify-center text-center cursor-pointer'
												onClick={() => handleForWhoRemove(item.order)}
											>
												{" "}
												Cikar{" "}
											</span>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>

				{course !== null ? (
					<div className='w-22 flex flex-row justify-center'>
						<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleSubmitCourseUpdate}>
							Güncelle
						</button>
					</div>
				) : (
					<div className='w-22 flex flex-row justify-center'>
						<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleSubmitCourseAdd}>
							Kayit Et
						</button>
					</div>
				)}
			</div>
		</>
	);
};

export default CourseAdd;
