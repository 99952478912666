import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
const MentorCourse = ({ mycourse }) => {
	const [courses, setCourses] = useState([]);
	const [course, setCourse] = useState();

	useEffect(() => {
		getCourses();
	}, []);

	const authCtx = useContext(AuthContext);

	useEffect(() => {
		console.log(authCtx);
	}, [authCtx]);

	useEffect(() => {
		getCourses();
	}, []);

	const handleCourse = (id) => {
		let selectedCourse = courses.find((item) => item.id == id);
		if (selectedCourse !== undefined) {
			setCourse(selectedCourse);
		} else setCourse(null);
	};

	const handleMentorCourse = () => {
		if (course != null) {
			const data = {
				mentoringCourseId: course.id,
			};

			console.log("data");
			console.log(data);
			console.log("mycourse");
			console.log(mycourse);

			fetch(`${process.env.REACT_APP_API_URL}/courses/${mycourse.id}`, {
				method: "PUT",
				mode: "cors",
				headers: {
					Authorization: `${authCtx.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((response) => console.log(response.json()))
				.then((data) => {
					console.log("Success:", data);
					console.log(`data`);
					console.log(data);
				})
				.catch((error) => {
					console.error("ErrorBurdan:", error);
				});
		} else {
			console.log("course bos");
		}
	};

	const getCourses = async () => {
		if (authCtx.token !== null) {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/userCourses/user`,
				{
					method: "GET",
					headers: {
						Authorization: `${authCtx.token}`,
						"Content-Type": "application/json",
					},
				}
			);
			const data = await response.json();
			console.log("courses");
			console.log(data);
			setCourses(data);

			let abc = data.find((item) => item.id === mycourse.mentoringCourseId);
			console.log("abc");
			console.log(abc);
			setCourse(abc);
		}
	};

	return (
		<>
			{mycourse.mentoringCourseId !== null ? (
				<div>{course && <div>Kurs adı : {course.name}</div>}</div>
			) : (
				<div className="flex">
					<select
						onChange={(e) => handleCourse(e.target.value)}
						className="p-2 border border-green-400 rounded-lg bg-blue-50"
					>
						<option value="0">Lütfen mentor kursunu seçiniz</option>
						{courses.map((item) => (
							<option value={item.id}>{item.name}</option>
						))}
					</select>
					<button onClick={handleMentorCourse}>Kaydet</button>
				</div>
			)}
		</>
	);
};

export default MentorCourse;
