import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const PdfViewer = ({ pdfUrl }) => {
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	return (
		<div className="m-1 h-[600px]">
			{isSafari ? (
				<>
					<span>PDF'yi görüntüleyemiyorsanız </span>
					<a
						href={pdfUrl}
						target="_blank"
						rel="noopener noreferrer"
						className="text-blue-500 underline"
					>
						buraya tıklayın
					</a>
				</>
			) : (
				<Worker
					workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
				>
					<Viewer fileUrl={pdfUrl} />
				</Worker>
			)}
		</div>
	);
};

export default PdfViewer;
