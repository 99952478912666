import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";



import MentorCourseProgress from "./MentorCourseProgress";
import MentorMenties from "./MentorMenties";

const MentorProgress = () => {
	const [course, setCourse] = useState(null);
	const [progress, setProgress] = useState(null);
	const authCtx = useContext(AuthContext);
	const [courseGoals, setGoals] = useState([]);
	const params = useParams();
	const { id } = params;

	useEffect(() => {
		console.log(authCtx);
		getLastChunkItems();
	}, []);

	const getLastChunkItems = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/userCourseProgresses/${id}`,
			{
				headers: {
					Authorization: `${authCtx.token}`,
					"Content-Type": "application/json",
				},
			}
		);
		const data = await response.json();
		console.log("chunkItems");
		console.log(data);
		setCourse(data.courseUserCourse);
		setProgress(data.progres);
	};

	return (
		<>
			{course !== null ? (
				<>
					{progress !== null && progress.isMentor == false ? (
						<MentorMenties courseId={course.id}/>
					) : (
						<MentorCourseProgress course={course} />
					)}
				</>
			) : (
				<div>Loading</div>
			)}
		</>
	);
};

export default MentorProgress;
