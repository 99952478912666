// WriterForm.js
import React, { useState, useEffect } from "react";
import PdfViewer from "../UI/PdfViewer";

const Reading = ({ chunkItem }) => {

	return (
		<>
			<div className="m-1">
				<h2 className="bg-green-200 w-fit">
					<strong>Yönerge :</strong> Lütfen aşağıdakileri okuyunuz ve sıradaki
					faliyete geçiniz.
				</h2>
			</div>
			<div className=" justify-center flex flex-row w-full mx-auto shadow-md rounded-md">
				<div className=" w-full">
					{/* <h1 className='text-3xl font-bold mb-4'>{chunkItem.readingContent.title}</h1> */}
					<div className="text-base w-full h-auto">
						 <PdfViewer pdfUrl={chunkItem.readingContent.link} />
					</div>
				</div>
			</div>
		</>
	);
};

export default Reading;
