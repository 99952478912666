import React from "react";


const Contact = () => {
	const data = [
		{
			title: "Yazılım Mühendisi Cem Gökçe",
			description: "",
			items: [
			<a className="text-white rounded-sm" target="_blank" href={"https://www.linkedin.com/in/cem-gokce-81a50964/"}>
				Cem Gökçe Linkedn için <span className="bg-blue-500 rounded-md p-0.25">Tıkla</span></a>

		],
			backGroundColor: "bg-red-500",
		},
		{
			title: "Mail",
			description: "",
			items: [
				"zincirokul@gmail.com",
			],
			backGroundColor: "bg-red-500",
		},
		{
			title: "Adres",
			description: "",
			items: [
				"Çanakkale Teknopark - Ön Kuluçka No:3",
				
			],
			backGroundColor: "bg-red-400",
		},
		{
			title: "Tamamlanma zamanı ",
			description: "",
			items: [
				"21 Kasım",
			],
			backGroundColor: "bg-red-400",
		},

		// Diğer kutuların verileri...
	];

	const Box = ({ title, description, items, backGroundColor }) => (
		<>
			<div className={`flex flex-col justify-center rounded  h-auto min-h-96  text-white md:min-w-[300px] ${backGroundColor ? backGroundColor : ""}`}>
				<div className='flex flex-col justify-center mx-5 p-4'>
					<div className='text-2xl'>{title}</div>
					<div className='text-xl mb-2'>{description}</div>
					<div className=' pl-10'>
						<ul className='list-disc tex-small'>
							{items.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	);



	return (
		<>
			<div className='flex flex-col'>
				<div className='bg-red-300 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl my-2'>
					<div className='flex flex-col justify-center'>İletişim</div>
				</div>
				<div className='container mx-auto p-4'>
					<div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
						{data.map((item, index) => (
							<Box key={index} {...item} />
						))}
					</div>
				</div>
				
			</div>
		</>
	);
};

export default Contact;
